import React, { useEffect, useRef, useState } from "react";

// redux
import { useSelectFromRedux, useDispatchPromise } from "../../utils/_hooks";
import { useDispatch } from "react-redux";

// components
import {
    CenteredDiv,
    FullscreenColor, 
    TopLogo,
} from "../site/styled";
import Site from "../site";
import Explore from '../explore';
import Trade from '../trade';
import Newsfeed from '../newsfeed';
import UpcomingEvents from '../upcoming_events';
import MyStable from '../my_stable';
import Account from '../account';
import { TopBar } from "./styled";
import { Button, ButtonGroup, ButtonProps, styled, TextField } from "@material-ui/core";
import { ContentActions, ContentState } from "../../state/content";
import { Row, VerticallyCenteredRow } from "../_shared";
import ParticleComp from '../_shared/particles';

export default (_props: any) => {
    const dispatch = useDispatch();
    // const dispatchPromise = useDispatchPromise();
    // const errorIsOpen = useSelectFromRedux((state) => state.error.isOpen);

    const Logo = 'https://tctrading.s3.amazonaws.com/utils/logo_notext.png';

    const [
        background,
        text,
        content,
        logo,
        lighthighlight,
        darkhighlight
    ] = useSelectFromRedux((state) => [
        state.color.scheme.background,
        state.color.scheme.text,
        state.content.content,
        state.color.general.logo,
        state.color.general.lighthighlight,
        state.color.general.darkhighlight
    ])

    const setContent = (c :  ContentState['content']) => {
        dispatch(ContentActions.setContent(c))
    }

    const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
        color: text,
        fontFamily: "Allerta",
        textTransform: 'none',
        backgroundColor: 'transparent'
      }));


    return (
        <FullscreenColor background={background}>
            {/* <TopBar style={{background: 'black', borderBottom: logo, borderStyle: 'solid', zIndex: 10}}>
                <Row>
                    <TopLogo
                        src={Logo}
                        alt="Logo"
                        onClick={() => setContent('home')}
                        style={{userSelect: 'none'}}
                    />
                    <ButtonGroup variant='text' aria-label ='outlined button group' color='inherit' style={{color: text}}>
                        <Button 
                        //     onClick={() => setContent('explore')} 
                        //     color='inherit' style={{color: content == 'explore' ? logo : text}}
                        // >Offerings</Button>
                        // <Button 
                        //     onClick={() => setContent('trade')}
                        //     color='inherit' style={{color: content == 'trade' ? logo : text}}
                        // >Trade</Button>
                        // <Button 
                        //     onClick={() => setContent('newsfeed')}
                        //     color='inherit' style={{color: content == 'newsfeed' ? logo : text}}
                        // >Newsfeed</Button>
                        // <Button 
                        //     onClick={() => setContent('upcoming events')}
                        //     color='inherit' style={{color: content == 'upcoming events' ? logo : text}}
                        // >Upcoming Events</Button>
                    </ButtonGroup>
                </Row>
                <ButtonGroup variant='text' aria-label ='outlined button group' color='inherit' style={{color: text, paddingRight: '20px'}}>
                    <Button 
                        onClick={() => setContent('my stable')}
                        color='inherit' style={{color: content == 'my stable' ? logo : text}}
                    >My Stable</Button>
                    <Button 
                        onClick={() => setContent('account')}
                        color='inherit' style={{color: content == 'account' ? logo : text}}
                    >Account</Button>
                </ButtonGroup>
            </TopBar> */}
            {/* <ParticleComp/> */}
            <Site/>
            {/* {content == 'home' && <Site/>}
            {content == 'explore' && <Explore/>}
            {content == 'trade' && <Trade/>}
            {content == 'newsfeed' && <Newsfeed/>}
            {content == 'upcoming events' && <UpcomingEvents/>}
            {content == 'my stable' && <MyStable/>}
            {content == 'account' && <Account/>} */}
            {/* <VerticallyCenteredRow style={{justifyContent:'space-around', paddingTop:'15px', paddingBottom:'5px'}}>
                {/* <ColorButton color='inherit' style={{color: content == 'privacy policy' ? logo : text, width: '33%'}} onClick={() => setContent('privacy policy')}>
                    Privacy Policy
                </ColorButton> */}
                {/* <div style={{zIndex: 10, textAlign:'center', width: '33%', color: darkhighlight}}>
                    {"\u00a9"} Triple Crown Trading 2023
                </div> */}
                {/* <ColorButton color='inherit' style={{color: content == 'terms of service' ? logo : text, width: '33%'}} onClick={() => setContent('terms of service')}>
                    Terms of Service
                </ColorButton> */}
            {/* </VerticallyCenteredRow> */}
        </FullscreenColor>
    );
};
