import React, { useEffect, useRef, useState } from "react";
import { BrowserView, isMobileOnly, MobileView } from "react-device-detect";
import { useParams } from "react-router-dom";
//import ReactPageScroller, {SectionContainer} from 'react-page-scroller';

// redux
import { useSelectFromRedux, useDispatchPromise } from "../../utils/_hooks";
import { useDispatch } from "react-redux";

// components
import {
    CenteredDiv,
    FullscreenColor,
    TopLogo,
    HorizontalBox,
    VerticalBox,
    CenterText,
    FadeDownImage,
    FadeImage,
    ZoomImage,
    CenteredText,
    FadeImageDelay
} from "./styled";
import { Badge, Button, ButtonProps, styled} from "@material-ui/core";
import store from "../../state/store";
import moment from "moment";
import { OwnerProfile } from "../../types";
import { Column, Row } from "../_shared";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import DoneIcon from "@mui/icons-material/Done";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import api from "../../api";
import { createTheme, TextField, ThemeProvider } from "@mui/material";
import { Element } from "./Element";

export default (_props: any) => {
    // const dispatch = useDispatch();
    // const dispatchPromise = useDispatchPromise();
    // const errorIsOpen = useSelectFromRedux((state) => state.error.isOpen);
    const [background, text, logo, darkhighlight, lighthighlight, lightaccent] = useSelectFromRedux((state) => [
        state.color.scheme.background,
        state.color.scheme.text,
        state.color.general.logo,
        state.color.general.darkhighlight,
        state.color.general.lighthighlight,
        state.color.general.lightaccent
    ]);

    const Verticallogo = "https://tctrading.s3.amazonaws.com/utils/logo_text_blue.png";
    const ScrollGif = "https://tctrading.s3.amazonaws.com/utils/scroll.gif";

    const [subIndex, setSubIndex] = useState(0);
    const [scrolled, setScrolled] = useState(0);

    const [name, setName] = useState<String>();
    const [email, setEmail] = useState<String>();
    const [enrolled, setEnrolled] = useState<Boolean>(false);

    const scroll1 = isMobileOnly ? window.innerHeight : window.innerHeight;
    const scroll2 = isMobileOnly ? 2800 : 2 * window.innerHeight + 200;
    const scroll3 = isMobileOnly ? 4200 : 3 * window.innerHeight + 200;

    const words = "An upcoming platform to buy and sell shares of thoroughbred racehorses.";

    const theme = createTheme({
        palette: {
            primary: {
                main: logo
            }
        },
        typography: {
            fontFamily: 'Allerta'
        },
    })

    const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
        color: text,
        fontFamily: "Allerta",
        textTransform: "none",
        backgroundColor: logo,
        "&:hover": {
            backgroundColor: lighthighlight
        },
        "&:disabled": {
            backgroundColor: logo
        }
    }));

    const expressInterest = async () => {
        const response = await api.post("/public/expressInterest", { name: name, email: email });
        if (response.data == "success") setEnrolled(true);
    };

    const [page, setPage] = useState<number>(0);

    // typeWriter
    useEffect(() => {
        if (subIndex === words.length) {
            return;
        }

        const timeout = setTimeout(() => {
            setSubIndex((prev) => prev + 1);
        }, 40);

        return () => clearTimeout(timeout);
    }, [subIndex]);

    const scroll = (n: number) => {
        n == scroll1 ? setScrolled(1) : n == scroll2 ? setScrolled(2) : setScrolled(3);
        window.scrollTo({
            top: n,
            behavior: "smooth"
            /* you can also use 'auto' behaviour
            in place of 'smooth' */
        });
    };

    const whoweare = (
        <Column style={{ height: window.innerHeight, paddingTop: '200px' }}>
            <CenteredText text={text}>
                <div style={{ fontSize: isMobileOnly ? "20px" : "25px", paddingBottom: "15px", color: lightaccent }}>
                    Invest Like Never Before
                </div>
                <div style={{ zIndex: 4, fontSize: isMobileOnly ? "13px" : "24px", paddingBottom: "20px", lineHeight: '32px' }}>
                    Our platform, backed by Templum Inc., allows you to buy and sell shares of thoroughbred racehorses - bringing innovative technology to horse racing.
                </div>
                <div style={{ fontSize: isMobileOnly ? "20px" : "25px", paddingBottom: "15px", color: lightaccent }}>
                    Don't Bet – Own
                </div>
                <div style={{ zIndex: 4, fontSize: isMobileOnly ? "13px" : "24px", marginBottom: "20px", lineHeight: '32px' }}>
                    We are providing a new opportunity to a formerly exclusive side of horse racing - ownership. Now, you can seamlessly buy shares of horses at affordable and transparent prices.
                </div>
                <div
                    style={{
                        fontSize: isMobileOnly ? "20px" : "25px",
                        paddingBottom: "15px",
                        zIndex: 4,
                        color: lightaccent
                    }}
                >
                    Experience True Horse Ownership
                </div>
                <div style={{ zIndex: 4, fontSize: isMobileOnly ? "13px" : "24px", lineHeight: '32px' }}>
                    You can engage with your horses through shareholder voting and play a role in the future of your portfolio. Watch your horses succeed on the racetrack and collect your share of their race earnings and stud fees.
                </div>
                <div
                    style={{
                        fontSize: isMobileOnly ? "20px" : "25px",
                        paddingBottom: "15px",
                        paddingTop: "20px",
                        color: lightaccent
                    }}
                >
                    Trade Freely
                </div>
                <div style={{ zIndex: 4, fontSize: isMobileOnly ? "13px" : "24px", marginBottom: "25px" , lineHeight: '32px'}}>
                    You can sell and profit at any time, no strings attached.
                </div>
            </CenteredText>
            <CenteredDiv text={text} style={{ paddingTop: "40px" }}>
                <BrowserView>
                    {page == 2 &&
                    <FadeImageDelay>
                        <img src={ScrollGif} alt="scroll" style={{width: '100px', zIndex: 3, marginLeft: '20px'}}/>
                        <div
                            style={{
                                zIndex: 4,
                                fontFamily: "Allerta",
                                marginBottom: "30px",
                                fontSize: "15px",
                                maxWidth: "250px",
                                textAlign: "center",
                                cursor: 'pointer'
                            }}
                            onClick={() => setPage(3)}

                        >
                            Keep Me Updated
                        </div>
                    </FadeImageDelay>}
                </BrowserView>
                <MobileView>
                    <ColorButton variant="contained" onClick={() => scroll(scroll3)} style={{ zIndex: 0 }}>
                        Keep Me Updated
                    </ColorButton>
                </MobileView>
            </CenteredDiv>
        </Column>
    );

    const expressinterest = (
        <Column style={{ minHeight: window.innerHeight }}>
            <CenteredText text={text}>
                <div
                    style={{
                        fontSize: isMobileOnly ? "40px" : "50px",
                        paddingBottom: "15px",
                        color: logo,
                        paddingTop: "200px"
                    }}
                >
                    Don't Wait.
                </div>
            </CenteredText>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                <ThemeProvider theme={theme}>
                    <TextField
                        label="Name"
                        variant="standard"
                        focused
                        style={{
                            width: isMobileOnly ? "60%" : "500px",
                            fontFamily: "Allerta",
                            marginBottom: "10px"
                        }}
                        sx={{input: {color: text}, color: text}}
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                    />
                    <TextField
                        label="Email"
                        variant="standard"
                        focused
                        style={{ width: isMobileOnly ? "60%" : "500px" }}
                        sx={{input: {color: text}, color: text}}
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                    />
                </ThemeProvider>
                <CenteredDiv text={text} style={{ paddingTop: "40px" }}>
                    <ColorButton variant="contained" onClick={() => expressInterest()} style={{ zIndex: 0 }}>
                        Join Our Waiting List
                    </ColorButton>
                    {enrolled && <DoneIcon fontSize="large" style={{ color: lighthighlight }} />}
                </CenteredDiv>
                {page == 3 &&
                <FadeImageDelay>
                    <img src={ScrollGif} alt="scroll" style={{width: '100px', zIndex: 3, transform: 'rotate(180deg)', marginTop: '100px'}}/>
                    <div
                        style={{
                            zIndex: 4,
                            fontFamily: "Allerta",
                            marginBottom: "30px",
                            fontSize: "15px",
                            maxWidth: "250px",
                            textAlign: "center",
                            cursor: 'pointer',
                            color: text
                        }}
                        onClick={() => setPage(0)}

                    >
                        Back to Top
                    </div>
                </FadeImageDelay>}
            </div>
        </Column>
    );

    const platformpreview = (
        <Column
            style={{
                minHeight: isMobileOnly ? 2 * window.innerHeight : window.innerHeight,
                paddingTop: "200px"
            }}
        >
            {/* <CenteredDiv text={text} style={{fontSize: isMobileOnly ? '20px' : '25px', paddingBottom: '15px', fontFamily: 'Allerta'}}>
                <div style={{zIndex: 4, fontFamily: 'Allerta', marginBottom: '30px', fontSize: '40px'}}>
                    How We Work
                </div>
            </CenteredDiv> */}
            <Row
                style={{
                    display: "flex",
                    flexDirection: isMobileOnly ? "column" : "row",
                    width: "80%",
                    marginLeft: "10%",
                    marginRight: "10%",
                    justifyContent: "center",
                    alignItems: "center",
                    flexWrap: isMobileOnly ? "wrap" : "nowrap"
                }}
            >
                <Column style={{ alignItems: "center", height: isMobileOnly ? "auto" : "300px" }}>
                    <TravelExploreIcon
                        color="inherit"
                        style={{ color: logo, cursor: "pointer" }}
                        sx={{ fontSize: "120px" }}
                    />
                    <CenteredDiv
                        text={text}
                        style={{
                            fontSize: isMobileOnly ? "20px" : "25px",
                            marginBottom: "15px",
                            fontFamily: "Allerta",
                            marginTop: "15px"
                        }}
                    >
                        <div
                            style={{
                                zIndex: 4,
                                fontFamily: "Allerta",
                                marginBottom: "20px",
                                fontSize: "20px",
                                textAlign: "center"
                            }}
                        >
                            Find Horses
                        </div>
                    </CenteredDiv>
                    <CenteredDiv
                        text={text}
                        style={{
                            fontSize: isMobileOnly ? "20px" : "25px",
                            marginBottom: "15px",
                            fontFamily: "Allerta"
                        }}
                    >
                        <div
                            style={{
                                zIndex: 4,
                                fontFamily: "Allerta",
                                marginBottom: "30px",
                                fontSize: "24px",
                                lineHeight: "32px",
                                maxWidth: "250px",
                                textAlign: "center"
                            }}
                        >
                            Browse assets in our marketplace with detailed information and analytics.
                        </div>
                    </CenteredDiv>
                </Column>
                {!isMobileOnly && <ArrowRightIcon color="inherit" style={{ color: logo }} sx={{ fontSize: "90px" }} />}
                {isMobileOnly && (
                    <ArrowDropDownIcon color="inherit" style={{ color: logo }} sx={{ fontSize: "90px" }} />
                )}
                <Column style={{ alignItems: "center", height: isMobileOnly ? "auto" : "300px" }}>
                    <SwapHorizIcon color="inherit" style={{ color: logo }} sx={{ fontSize: "120px" }} />
                    <CenteredDiv
                        text={text}
                        style={{
                            fontSize: isMobileOnly ? "20px" : "25px",
                            marginBottom: "15px",
                            fontFamily: "Allerta",
                            marginTop: "15px"
                        }}
                    >
                        <div
                            style={{
                                zIndex: 4,
                                fontFamily: "Allerta",
                                marginBottom: "20px",
                                fontSize: "20px",
                                textAlign: "center"
                            }}
                        >
                            Buy & Sell
                        </div>
                    </CenteredDiv>
                    <CenteredDiv
                        text={text}
                        style={{
                            fontSize: isMobileOnly ? "20px" : "25px",
                            marginBottom: "15px",
                            fontFamily: "Allerta"
                        }}
                    >
                        <div
                            style={{
                                zIndex: 4,
                                fontFamily: "Allerta",
                                marginBottom: "30px",
                                fontSize: "15px",
                                maxWidth: "250px",
                                textAlign: "center"
                            }}
                        >
                            Build your racehorse portfolio in minutes and enjoy the perks of ownership.
                        </div>
                    </CenteredDiv>
                </Column>
                {/* {!isMobileOnly && <ArrowRightIcon
                    color='inherit'
                    style={{color: logo}}
                    sx={{fontSize: '90px'}}
                />}
                {isMobileOnly && <ArrowDropDownIcon
                    color='inherit'
                    style={{color: logo}}
                    sx={{fontSize: '90px'}}
                />}
                <Column style={{alignItems: 'center', height: isMobileOnly ? 'auto' : '300px'}}>
                    <SwapHorizIcon
                        color='inherit'
                        style={{color: logo}}
                        sx={{fontSize: '120px'}}
                    />
                    <CenteredDiv text={text} style={{fontSize: isMobileOnly ? '20px' : '25px', marginBottom: '15px', fontFamily: 'Allerta', marginTop: '15px'}}>
                        <div style={{zIndex: 4, fontFamily: 'Allerta', marginBottom: '20px', fontSize: '20px', textAlign: 'center'}}>
                            Trade
                        </div>
                    </CenteredDiv>
                    <CenteredDiv text={text} style={{fontSize: isMobileOnly ? '20px' : '25px', marginBottom: '15px', fontFamily: 'Allerta'}}>
                        <div style={{zIndex: 4, fontFamily: 'Allerta', marginBottom: '30px', fontSize: '15px', maxWidth: '250px', textAlign: 'center'}}>
                            Watch your assets' progress, profit off its winnings, trade your shares for other horses, or convert back to USD whenever you want.
                        </div>
                    </CenteredDiv>
                </Column> */}
                {!isMobileOnly && <ArrowRightIcon color="inherit" style={{ color: logo }} sx={{ fontSize: "90px" }} />}
                {isMobileOnly && (
                    <ArrowDropDownIcon color="inherit" style={{ color: logo }} sx={{ fontSize: "90px" }} />
                )}
                <Column style={{ alignItems: "center", height: isMobileOnly ? "auto" : "300px" }}>
                    <AttachMoneyIcon color="inherit" style={{ color: logo }} sx={{ fontSize: "120px" }} />
                    <CenteredDiv
                        text={text}
                        style={{
                            fontSize: isMobileOnly ? "20px" : "25px",
                            marginBottom: "15px",
                            fontFamily: "Allerta",
                            marginTop: "15px"
                        }}
                    >
                        <div
                            style={{
                                zIndex: 4,
                                fontFamily: "Allerta",
                                marginBottom: "20px",
                                fontSize: "20px",
                                textAlign: "center"
                            }}
                        >
                            Profit
                        </div>
                    </CenteredDiv>
                    <CenteredDiv
                        text={text}
                        style={{
                            fontSize: isMobileOnly ? "20px" : "25px",
                            marginBottom: isMobileOnly ? "0px" : "15px",
                            fontFamily: "Allerta"
                        }}
                    >
                        <div
                            style={{
                                zIndex: 4,
                                fontFamily: "Allerta",
                                marginBottom: isMobileOnly ? "0px" : "30px",
                                fontSize: "15px",
                                maxWidth: "250px",
                                textAlign: "center"
                            }}
                        >
                            Watch your stable grow. Receive your earnings. Win big.
                        </div>
                    </CenteredDiv>
                </Column>
            </Row>
            <CenteredDiv text={text}>
                <BrowserView>
                    {page == 1 &&
                    <FadeImageDelay>
                        <img src={ScrollGif} alt="scroll" style={{width: '100px', zIndex: 3}}/>
                        <div
                            style={{
                                zIndex: 4,
                                fontFamily: "Allerta",
                                marginBottom: "30px",
                                fontSize: "15px",
                                maxWidth: "250px",
                                textAlign: "center",
                                cursor: 'pointer'
                            }}
                            onClick={() => setPage(2)}

                        >
                            Read More
                        </div>
                    </FadeImageDelay>}
                </BrowserView>
                <MobileView>
                    <ColorButton
                        variant="contained"
                        onClick={() => scroll(scroll2)}
                        style={{ zIndex: 0, marginTop: isMobileOnly ? "50px" : "100px" }}
                    >
                        Read More
                    </ColorButton>
                </MobileView>
            </CenteredDiv>
        </Column>
    );

    return (
        <Element/>
        // <div style={{ overflow: "hidden" }}>
        //     <BrowserView>
        //         <ReactPageScroller
        //             pageOnChange={setPage}
        //             customPageNumber={page}
        //             blockScrollDown={(subIndex !== words.length)}
        //             blockScrollUp={(subIndex !== words.length)}
        //         >
        //         <div style={{ height: window.innerHeight }}>
        //             <CenteredDiv text={text}>
        //                 <FadeDownImage style={{ zIndex: 0, marginTop: "160px", width: isMobileOnly ? "70%" : "500px" }}>
        //                     <img src={Verticallogo} alt="mainlogo" style={{ width: "100%", zIndex: 3 }} />
        //                 </FadeDownImage>
        //             </CenteredDiv>
        //             <CenteredDiv
        //                 style={{
        //                     paddingTop: "20px",
        //                     fontSize: "15px",
        //                     zIndex: 4,
        //                     fontFamily: "Allerta",
        //                     textAlign: "center",
        //                     marginRight: "10%",
        //                     marginLeft: "10%"
        //                 }}
        //                 text={text}
        //             >
        //                 <div style={{ zIndex: 4 }}>{`${words.substring(0, subIndex)}`}</div>
        //             </CenteredDiv>
        //             <CenteredDiv style={{ paddingTop: "40px" }} text={logo}>
        //                 {(subIndex === words.length) && (page == 0) &&
        //                 <FadeImage>
        //                     <img src={ScrollGif} alt="scroll" style={{width: '100px', zIndex: 3, alignSelf: 'center', marginLeft: '30px'}}/>
        //                     <div
        //                         style={{
        //                             zIndex: 4,
        //                             fontFamily: "Allerta",
        //                             color: text,
        //                             marginBottom: "30px",
        //                             fontSize: "15px",
        //                             maxWidth: "250px",
        //                             textAlign: "center",
        //                             cursor: 'pointer'
        //                         }}
        //                         onClick={() => setPage(1    )}

        //                     >
        //                     Learn How We Work
        //                 </div>
        //                 </FadeImage>
        //                 }
        //             </CenteredDiv>
        //         </div>
        //         {platformpreview}
        //         {whoweare}
        //         {expressinterest}
        //         </ReactPageScroller>
        //     </BrowserView>
        //     <MobileView>
        //         <div style={{ height: window.innerHeight }}>
        //             <CenteredDiv text={text}>
        //                 <FadeDownImage style={{ zIndex: 0, marginTop: "160px", width: isMobileOnly ? "70%" : "500px" }}>
        //                     <img src={Verticallogo} alt="mainlogo" style={{ width: "100%", zIndex: 3 }} />
        //                 </FadeDownImage>
        //             </CenteredDiv>
        //             <CenteredDiv
        //                 style={{
        //                     paddingTop: "20px",
        //                     fontSize: "15px",
        //                     zIndex: 4,
        //                     fontFamily: "Allerta",
        //                     textAlign: "center",
        //                     marginRight: "10%",
        //                     marginLeft: "10%"
        //                 }}
        //                 text={text}
        //             >
        //                 <div style={{ zIndex: 4 }}>{`${words.substring(0, subIndex)}`}</div>
        //             </CenteredDiv>
        //             <CenteredDiv style={{ paddingTop: "40px", fontFamily: "Allerta" }} text={logo}>
        //                 <ColorButton
        //                     variant="contained"
        //                     onClick={() => scroll(scroll1)}
        //                     disabled={subIndex !== words.length}
        //                 >
        //                     Learn How We Work
        //                 </ColorButton>
        //             </CenteredDiv>
        //         </div>
        //         {platformpreview}
        //         {whoweare}
        //         {expressinterest}
        //     </MobileView>
        //     {/* {scrolled != 1 && platformpreview}
        //     {scrolled == 1 &&
        //     <ZoomImage>
        //         {platformpreview}
        //     </ZoomImage>
        //     }
        //     {scrolled != 2 && whoweare}
        //     {scrolled == 2 && 
        //     <ZoomImage>
        //         {whoweare}
        //     </ZoomImage>
        //     }
        //     {scrolled != 3 && expressinterest}
        //     {scrolled == 3 &&
        //     <ZoomImage>
        //         {expressinterest}
        //     </ZoomImage>
        //     } */}
        // </div>
    );
};
