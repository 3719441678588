import React, { useRef, useState } from "react";
import "./style.css";
import { FormGroup, FormField, ModalMakeOrderButton } from "../_shared";
import api from "../../api";
import { isMobileOnly } from "react-device-detect";

export const Element = (): JSX.Element => {

  const us = [{name: 'Nico Bowden', school: 'Massachusetts Institute of Technology', pic: 'https://tctrading.s3.amazonaws.com/profiles/nico.jpeg'},
              {name: 'Jack Bolton', school: 'University of Southern California', pic: 'https://tctrading.s3.amazonaws.com/profiles/jack.png'}]

  const board = [{name: "Michael Kim", role: "FOUNDER OF CENDANA CAPITAL", pic: 'https://tctrading.s3.amazonaws.com/profiles/kim.png'},
                {name: "Bill Farish", role: `CEO OF LANE'S END FARM`, pic: 'https://tctrading.s3.amazonaws.com/profiles/bill.png'},
                {name: "Ben Haggin", role: "COO OF WOODFORD RACING", pic: 'https://tctrading.s3.amazonaws.com/profiles/ben.png'}]

  const waitlist = useRef(null);
  const executeScroll = () => {
    if(waitlist && waitlist.current) {
      //@ts-ignore
      waitlist.current.scrollIntoView({behavior: 'smooth'});
    }
  }

    const [name, setName] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [enrolled, setEnrolled] = useState<Boolean>(false);
    const [bademail, setbademail] = useState<Boolean>(false);

    const video = 'https://tctrading.s3.amazonaws.com/utils/horsevid.mp4';

    const expressInterest = async () => {

        // setbademail(false);
        setEnrolled(false);
        // var config = {
        //   method: 'get',
        //   maxBodyLength: Infinity,
        //   url: `https://api.usebouncer.com/v1.1/email/verify?email=${email}&timeout=10`,
        //   headers: { 
        //       'x-api-key': 'l6j3Qc7ub4pN6AWAGWFN6UUmwsCpxMxol41VfMkI',
        //       'Access-Control-Allow-Origin' : '*',
        //       'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        //       'Access-Control-Allow-Credentials': true
        //   }
        //   };

        //   const response = await axios(config)

        //   if(response.data.status == 'deliverable' && response.data.reason == 'accepted_email') {
        //     const response2 = await api.post("/public/expressInterest", { name: 'undefined', email: email });
        //     if (response2.data == "success") setEnrolled(true);
        //   } else {
        //       setbademail(true);
        //   }
        const response2 = await api.post("/public/expressInterest", { name: 'undefined', email: email });
            if (response2.data == "success") setEnrolled(true);
    };
  
  return (
    <div className="element">
      <div className={isMobileOnly ? "mobile-div" : "div"}>
        <div className={isMobileOnly ? "mobile-landing" : "landing"}>
          <div className="overlap-group">
            <img className={isMobileOnly ? "mobile-horsepic" : "horsepic"} alt="Horse Picture" src="https://tctrading.s3.amazonaws.com/utils/horsepic.jpeg"/>
            <img className={isMobileOnly ? "mobilelogo" : "logo"}alt="Logo" src="https://tctrading.s3.amazonaws.com/logos/LogoM.png" />
            <div className="text-wrapper">TRIPLE CROWN TRADING</div>
            <div className={isMobileOnly ? "mobile-landing-2" : "landing-2"}>
              <h1 className="h-1">Don’t bet. Own.</h1>
              <p className={isMobileOnly ? "mobile-p" : "p"}>An upcoming platform to purchase shares of thoroughbred racehorses.</p>
              <div className="join-the-waitlist" >
                {/* <div className="frame" onClick={executeScroll}>
                  <div className="text-wrapper-2">JOIN THE WAITLIST</div>
                </div> */}
                <ModalMakeOrderButton
                        style={{ fontSize: '20px', lineHeight: '32px', fontFamily: "IBM Plex Mono", borderRadius: '20px', boxShadow:'none', fontWeight: 500, gap: '8px', padding: 'var(--space-xxxs, 4px) var(--space-s, 23.1px)',}}
                        onClick={executeScroll}
                        variant='contained'
                        onMouseEnter={(event) => {
                          const target = event.target as HTMLElement;
                          target.style.backgroundColor = '#004381';
                      }}
                      onMouseLeave={(event) => {
                          const target = event.target as HTMLElement;
                          target.style.backgroundColor = '#0085FF';
                      }}
                >
                        Join The Waitlist
                </ModalMakeOrderButton>
              </div>
            </div>
          </div>
        </div>
        <div className={isMobileOnly ? "mobile-sticky-pad" : "sticky-pad"} style={{overflowX: 'hidden'}}/>
        <div style={{ 'position': 'sticky', 'top': '0px', height: '100vh', overflow: 'hidden' }}>
          <video autoPlay loop muted playsInline id='video' className={isMobileOnly ? "mobile-adobestock" : "adobestock"}>
            <source src={video} type={'video/mp4'}/>
          </video>
        </div>
        <div className={isMobileOnly ? "mobile-why-we-re-better" : "why-we-re-better"}>
          <div className={isMobileOnly ? "mobile-perks" : "perks"}>
            <div className={isMobileOnly? "mobile-div-2" : "div-2"}>
              <div className={isMobileOnly ? "mobile-text-wrapper-3" : "text-wrapper-3"}>Invest like never before.</div>
              <p className={isMobileOnly ? "mobile-text-wrapper-4" : "text-wrapper-4"}>
                Our platform allows you to purchase ownership shares of thoroughbred racehorses -
                bringing innovative technology to horse racing.
              </p>
            </div>
            <div className="div-3">
              <div className={isMobileOnly ? "mobile-text-wrapper-3" : "text-wrapper-3"}>Don’t bet -- own.</div>
              <p className={isMobileOnly ? "mobile-text-wrapper-4" : "text-wrapper-4"}>
                We are providing a new opportunity to a formerly exclusive side of horse racing - ownership. Now, you
                can seamlessly buy shares of horses at transparent prices.
              </p>
            </div>
            <div className="div-2">
              <div className={isMobileOnly ? "mobile-text-wrapper-3" : "text-wrapper-3"}>Experience true horse ownership.</div>
              <p className={isMobileOnly ? "mobile-text-wrapper-4" : "text-wrapper-4"}>
                When TCT owns the majority of a horse, you can engage in certain training and racing decisions through shareholder voting. Watch your horses succeed on the racetrack and collect your share of their race earnings
                and stud fees.
              </p>
            </div>
            <div className="div-3">
              <div className={isMobileOnly ? "mobile-text-wrapper-3" : "text-wrapper-3"}>Trade freely.</div>
              <p className={isMobileOnly ? "mobile-text-wrapper-4" : "text-wrapper-4"}>After our first offerings, we look to expand into a secondary marketplace, allowing for liquidity and secure trading.</p>
            </div>
          </div>
          <div className={isMobileOnly ? "mobile-text-wrapper-5" : "text-wrapper-5"}>WHY WE’RE BETTER</div>
        </div>
        <div className={isMobileOnly ? "mobile-whoweare" : "whoweare"}>
          <div className="text-wrapper-we">WHO WE ARE</div>
          <div className={isMobileOnly ? "mobile-people" : "people"}>
              {us.map((person) => (
                  <div className={isMobileOnly ? 'mobile-person' : 'person'}>
                    <img src={person.pic} style={{width: isMobileOnly ? '70vw' : '350px', height: isMobileOnly ? '70vw' : '350px', objectFit: 'cover', borderRadius: isMobileOnly ? '35vw' :'175px'}}/>
                    <div style={{fontFamily: 'Abhaya Libre', fontSize: '26px', marginTop: '30px'}}>
                        {person.name}
                    </div>
                    <div style={{fontFamily: 'IBM Plex Sans', fontSize: '16px', marginTop: '15px'}}>
                      CO-FOUNDER
                    </div>
                    <div style={{fontFamily: 'Abhaya Libre', fontSize: isMobileOnly ? '20px' : '26px', marginTop: '15px', textAlign: 'center'}}>
                        {person.school}
                    </div>
                  </div>
              ))}
          </div>
        </div>
        <div className={isMobileOnly ? "mobile-board" : "board"}>
          <div className="text-wrapper-we">OUR ADVISORY BOARD</div>
          <div className={isMobileOnly ? "mobile-people" : "people"}>
              {board.map((person) => (
                  <div className={isMobileOnly ? 'mobile-person' : 'person'}>
                    <img src={person.pic} style={{width: isMobileOnly ? '70vw' : '350px'}}/>
                    <div style={{fontFamily: 'Abhaya Libre', fontSize: '26px', marginTop: '30px'}}>
                        {person.name}
                    </div>
                    <div style={{fontFamily: 'IBM Plex Sans', fontSize: '16px', marginTop: '15px'}}>
                        {person.role}
                    </div>
                  </div>
              ))}
          </div>
        </div>
        <div className={isMobileOnly ? "mobile-bottom-CTA" : "bottom-CTA"} ref={waitlist}>
          <p className={isMobileOnly ? "mobile-text-wrapper-6" : "text-wrapper-6"}>© Triple Crown Trading 2024</p>
          {/* <p className={isMobileOnly ? "mobile-text-wrapper-d" : "text-wrapper-d"}>Primary offering securities are offered through Templum Markets, LLC, Member FINRA/SIPC.</p> */}
          <p className={isMobileOnly ? "mobile-text-wrapper-7" : "text-wrapper-7"}>Receive exclusive perks when you join early.</p>
          <div className={isMobileOnly ? "mobile-text-wrapper-8" : "text-wrapper-8"}>Don’t wait.</div>
          <div className={isMobileOnly ? "mobile-join-the-waitlist-2" : "join-the-waitlist-2"}>
              <FormGroup style={{display: 'inline-flex', flexDirection: 'row', width: isMobileOnly? '300px' : '507px'}}>
              <FormField style= {{fontSize: '16px', lineHeight: '24px', color: 'black'}}
                  type="input"
                  placeholder="EMAIL ADDRESS"
                  id="email"
                  required
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  />
                  {!isMobileOnly && <ModalMakeOrderButton
                      style={{ alignSelf: "center", width: '120px', fontSize: '1em', color: 'white', fontFamily: "IBM Plex Mono", borderRadius: '20px', marginLeft: '24px', boxShadow: 'none'}}
                      variant='contained'
                      onClick={expressInterest}
                      onMouseEnter={(event) => {
                        const target = event.target as HTMLElement;
                        target.style.backgroundColor = '#004381';
                    }}
                    onMouseLeave={(event) => {
                        const target = event.target as HTMLElement;
                        target.style.backgroundColor = '#0085FF';
                    }}
                >
                        Submit
                </ModalMakeOrderButton>}
              </FormGroup>
              {isMobileOnly && <ModalMakeOrderButton
                      style={{ alignSelf: "center", width: '300px', fontSize: '1em', color: 'white', fontFamily: "IBM Plex Mono", borderRadius: '20px', boxShadow: 'none'}}
                      variant='contained'
                      onClick={expressInterest}
                      onMouseEnter={(event) => {
                        const target = event.target as HTMLElement;
                        target.style.backgroundColor = '#004381';
                    }}
                    onMouseLeave={(event) => {
                        const target = event.target as HTMLElement;
                        target.style.backgroundColor = '#0085FF';
                    }}
                >
                        Submit
                </ModalMakeOrderButton>}
              
              {enrolled && <p className="text-wrapper-15">Thanks! You'll hear from us soon.</p>}
              {bademail && <p className="text-wrapper-15" style={{color: 'red'}}>Please enter a valid email address.</p>}
          </div>
        </div>
        <div className={isMobileOnly ? "mobile-how-it-works" : "how-it-works"}>
          <div className="text-wrapper-11">HOW IT WORKS</div>
          <div className={isMobileOnly ? "mobile-how-it-works-3" : "how-it-works-2"}>
            <div className={isMobileOnly ? "mobile-div-4" : "div-4"}>
              <img className="vector" alt="Vector" src="https://tctrading.s3.amazonaws.com/utils/vector-2.svg" />
              <div className="text-wrapper-12">Find horses</div>
              <p className="text-wrapper-13">
                Browse assets in our marketplace with detailed information and analytics.
              </p>
            </div>
            <div className={isMobileOnly ? "mobile-div-4" : "div-4"}>
              <img className="img" alt="Vector" src="https://tctrading.s3.amazonaws.com/utils/vector-1.svg" />
              <div className="text-wrapper-12">Invest</div>
              <p className="text-wrapper-13">
                Build your racehorse portfolio in minutes and enjoy the perks of ownership.
              </p>
            </div>
            <div className={isMobileOnly ? "mobile-div-4" : "div-4"}>
              <img className="vector-2" alt="Vector" src="https://tctrading.s3.amazonaws.com/utils/vector.svg" />
              <div className="text-wrapper-12">Profit</div>
              <p className="text-wrapper-13">Watch your stable grow. Receive your earnings. Have a chance to win big.</p>
            </div>
          </div>
        </div>
        <div className={isMobileOnly ? "mobile-partners" : "partners"}>
          <div style={{display: 'flex', flexDirection: 'row', width: '100vw', justifyContent: 'space-evenly'}}>
            <div className="text-wrapper-14" style={{width: '58vw', textAlign: 'center'}}>PARTNERED WITH</div>
          </div>
          <div className={isMobileOnly ?  "mobile-logos" : "logos"}>
            <img className={isMobileOnly ? "mobile-lane-s-end" : "lane-s-end"} alt="Lane s end" src="https://tctrading.s3.amazonaws.com/utils/lane-s-end.png" />
            <img className={isMobileOnly ? "mobile-woodford" : "woodford"} alt="Woodford logo" src="https://tctrading.s3.amazonaws.com/utils/woodford.jpg" /> 
          </div>
        </div>
        {/* <div className={isMobileOnly ? "mobile-partners2" : "partners2"}>
          <div style={{display: 'flex', flexDirection: isMobileOnly ? 'column' : 'row', width: '100vw', justifyContent: 'center', alignContent: 'center', alignItems: 'center'}}>
            <div className="text-wrapper-18" style={{width: 'auto', textAlign: 'center', marginRight: isMobileOnly? 0 : '50px', marginTop: '15px'}}>POWERED BY</div>
            <img className={isMobileOnly ? "mobile-templum-logo" : "templum-logo"} alt="Templum logo" src="https://tctrading.s3.amazonaws.com/utils/templum-logo.png" />
          </div>
          <div className={isMobileOnly ?  "mobile-logos2" : "logos2"}>
            <img className={isMobileOnly ? "mobile-templum-logo" : "templum-logo"} alt="Templum logo" src="https://tctrading.s3.amazonaws.com/utils/templum-logo.png" /> 
          </div>
        </div> */}
      </div>
    </div>
  );
};
